import React, { useCallback, useEffect, useState } from 'react';
import { getFormsLink } from '../util/getters';
import { useSnackbar } from "notistack";
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

    const handleSubmit = useCallback(
      async (e) => {
        e.preventDefault();
        try {
          const endpoint = getFormsLink(
           formData.name,
           formData.email,
           formData.subject,
           formData.message,
          );
          await fetch(endpoint, { method: "GET", mode: "no-cors" })
          .then((res) => {
            if(res){
              setFormData({
                name: '',
                email: '',
                subject: '',
                message: ''
              })
            setSuccess(true)
           
           }
            enqueueSnackbar("Request Submitted", { variant: "success" });
           })

          
        } catch (err) {
          enqueueSnackbar(err.message || "Failed to Submit Request", {
            variant: "error",
          });
          console.error(err);
        }
      },
      [formData]
    );

    useEffect(() => {
      let timeout;
      if (success) {
        timeout = setTimeout(() => {
          setSuccess(false);
        }, 5000); // 8 seconds
      }
      return () => clearTimeout(timeout);
    }, [success]);


  return (
    <div className="lg:w-2/3 w-full mx-auto bg-white p-8 rounded-md shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
      {success && (
        <h1 className="text-3xl text-green-600 font-semibold p-2 bg-green-200 rounded-lg w-full">
          Message Sent SuccessFully
        </h1>
      )}
      <form onSubmit={handleSubmit}>
       <div className='w-full flex justify-between gap-8'>
       <div className="mb-4 w-full">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your Name"
            required
          />
        </div>
        <div className="mb-4 w-full">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your Email"
            required
          />
        </div>
       </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subject">
            Subject
          </label>
          <input
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            type="text"
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            placeholder="Subject"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            placeholder="Your Message"
            required
          ></textarea>
        </div>
        
        <div className='flex justify-center'>
        <button className="rounded-md bg-btnBg hover:bg-primary text-primary hover:text-btnBg hover:shadow-lg text-[24px] py-1 px-5  mt-9 text-[#042739] w-[294px] h-[62px] text-[23]">
             Submit
            </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;

import React from 'react';
import blog from "../assets/images/blog.jpg"

const BlogConte = () => {
  const blogs = [
    {
      id: 1,
      title: 'John Doe',
      img: blog,
      date: 'May 1, 2024',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
    },
    
    // Add more blog objects as needed
  ];

  return (
    <div className="max-w-screen-lg mx-auto">
      {blogs.map(blog => (
        <div key={blog.id} className="mb-8">
          <img src={blog.img} alt={"blog.title"} className="w-full h-[500px] object-cover mb-4  shadow-lg rounded-md" />


          <div className='flex gap-3'>
          <div className="flex items-center mb-2">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 fill-current" viewBox="0 0 24 24">
  <path d="M12 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 2c3.086 0 6.758 1.86 8.535 4.565C19.875 19.69 16.163 22 12 22s-7.875-2.31-8.535-5.435C5.242 15.86 8.914 14 12 14zm0-10c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"/>
</svg>

            <h2 className="text-md text-gray-500">{blog.title}</h2>
          </div>
          <div className="flex items-center mb-2">
            <svg className="w-6 h-6 fill-current text-gray-600 mr-2" viewBox="0 0 24 24">
              <path d="M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zm0 18c-3.313 0-6-2.687-6-6s2.687-6 6-6 6 2.687 6 6-2.687 6-6 6zm-2-6h4v2h-4v-2z"/>
            </svg>
            <p className="text-md text-gray-500">{blog.date}</p>
          </div>
          </div>
          
          <p className="text-gray-700 w-w-max-8xl">{blog.description}</p>
        </div>
      ))}
    </div>
  );
};

export default BlogConte;

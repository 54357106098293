import React, { useEffect, useState } from "react";
import { technologeStack } from "../../type/enum";

const TechnologStack = () => {
  const [seeAll , setSeeAll] = useState(false)
  const [limit , setLimit] = useState(2)

  useEffect(() => {
    if(seeAll === false){
      setLimit(2)
    }else{
      setLimit(technologeStack?.length)
    }
  }, [seeAll])

  
  return (
    <section className="max-w-screen-xl gl:mx-auto md:mx-auto mx-4 py-16">
      <div className="lg:w-[422px] w-full h-fit  mx-auto ">
        <h2 className="font-bold md:text-[42px] text-[20px] text-center my-8 ">
          Technology Stack We Believe In
        </h2>
      </div>

      <div className="grid lg:grid-cols-2 grid-rows gap-1 justify-center">
        {technologeStack.slice(0, limit).map((item , index) => (
          <div key={index} className="shadow-md border border-[#BFBFBF] h- ">
            <h2 className="text-center font-bold md:text-[38px] mt-1 text-[24px]">
              {item.name}
            </h2>
            <img src={item.img} className="p-5" />
          </div>
        ))}
      </div>
      
      <div className="w-full m-auto justify-center flex mt-8">
        <button onClick={() => setSeeAll(!seeAll)} className="w-[354px] h-[60px] text-[20px] bg-btnBg hover:bg-primary  rounded-full shadow-lg text-primary hover:text-btnBg font-bold">{seeAll ? 'Collapse' : 'See All'}</button>
      </div>

    </section>
  );
};

export default TechnologStack;

import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import log from "../assets/logo/logo.png"
const NavbarLayout = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const [activeHeader, setActiveHeader] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);

  // Do something based on the current path
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    const getCurrentUrl = () => {
      setCurrentUrl(window.location.pathname + window.location.hash);
    };

    getCurrentUrl();

    // Listen to hash changes
    window.addEventListener('hashchange', getCurrentUrl);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', getCurrentUrl);
    };
  }, []);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.scrollY;
  //     setIsScrolled(scrollTop > 0);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (menuRef.current && !menuRef.current.contains(event.target)) {
  //       setIsOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (header) => {
    setActiveHeader(header);
  };

  
  return (
    <nav className={`bg-gradient-to-r from-[#0C59E4] from-0% via-[#138CCD] via-37% to-[#00C9AB] to-96% p-4  fixed top-0 z-10 w-full `}>
      <div className=" max-w-screen-xl mx-auto flex justify-between items-center">
        <div className="w-full flex justify-between">
          <div className="flex items-center">
            <div className="lg:hidden md:mr-0 sm:mr-10">
              <button
                onClick={toggleNavbar}
                className="text-gray-400 focus:outline-none "
              >
                <svg
                  className="h-6 w-6 "
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
              </button>
            </div>
            <Link to="/" >
              <img
                src={log}
                className="h-[35px] w-[120px] lg:h-full lg:w-[200px]"
              />
            </Link>
          </div>

          <div className="hidden text-primary lg:flex gap-6 mt-4 mr-6">
            {/* <a href="/#"  className={`focus:outline-none ${
                currentUrl === "/" ? "font-bold" : ""
              } hover:font-bold`}
             >
              Home
            </a> */}
            <Link to={'/'} className="hover:font-bold">
              Home
            </Link>
             {/* <a href="/#"  className={`hover:font-bold`}
             >
              Home
            </a> */}

            <a
              href="/#aboutUs"
              className={`focus:outline-none hover:font-bold`}
              
            >
              AboutUs
            </a>
            <a
              href="/#services"
              className={`focus:outline-none  hover:font-bold`}
            
            >
              Services
            </a>
            <a
              href="/#industry"
              className={`focus:outline-none  hover:font-bold`}
              
            >
              Industry
            </a>
            <a
              href="/#portfolio"
              className={`focus:outline-none  hover:font-bold`}
              
            >
              Portfolio
            </a>

            <a
              href={`/#testimonials`}
              className={`focus:outline-none  hover:font-bold`}
           
            >
              Testimonials
            </a>

            <Link to={"/blogs"}  >
              <span className="hover:font-bold">Blogs</span>
            </Link>
            <a
              href={`/#contactus`}
              className={`focus:outline-none  hover:font-bold`}
           
            >ContactUS</a>
            <Link
              to={"/careers"}
              
            >
              <span className="hover:font-bold">Careers</span>
            </Link>
          </div>

          <a
            target="_blank"
            href="https://calendly.com/khizarhussain"
            className="bg-primary text-[#1E907F] shadow-lg cursor-pointer rounded-full px-3 text-center w-[190px] h-[45px] font-bold text-[14px]   pt-3  mt-2 "
          >
            Book An Appointment
          </a>
        </div>

        {/* Humbarger */}
      </div>
      {/* Responsive Hamburger Menu */}
      {isOpen && (
        <div
          className={`md:hidden fixed top-0 left-0 h-screen w-full bg-gray-800 bg-opacity-75 z-20 flex justify-start`}
        >
          <div
            ref={menuRef}
            className={`bg-btnBg text-white w-[50%] h-fit left-0 mt-12  p-4`}
          >
            {/* <div className="flex justify-end">
              <button 
                onClick={toggleNavbar}
                className="text-white focus:outline-none">
                <svg
                  className="h-6 w-6"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div> */}
            <div className="flex flex-col h-full content-between gap-8  items-start ">
            <a href="/#"  className={`hover:font-bold`}
             >
              Home
            </a>

            <a
              href="/#aboutUs"
              className={`focus:outline-none hover:font-bold`}
              
            >
              AboutUs
            </a>
            <a
              href="/#services"
              className={`focus:outline-none  hover:font-bold`}
            
            >
              Services
            </a>
            <a
              href="/#industry"
              className={`focus:outline-none  hover:font-bold`}
              
            >
              Industry
            </a>
            <a
              href="/#portfolio"
              className={`focus:outline-none  hover:font-bold`}
              
            >
              Portfolio
            </a>

            <a
              href={`/#testimonials`}
              className={`focus:outline-none  hover:font-bold`}
           
            >
              Testimonials
            </a>

            <Link to={"/blogs"}  >
              <span className="hover:font-bold">Blogs</span>
            </Link>
            
            
            
            <a
              href={`/#contactUs`}
              className={`focus:outline-none  hover:font-bold`}
           
            >ContactUS</a>
           

            <Link
              to={"/careers"}
              
            >
              <span className="hover:font-bold">Careers</span>
            </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavbarLayout;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import img from "../assets/images/portfolio/MartitimeVesseel.png"

const Slider = ({ data, is }) => {
  const contentRef = useRef(null);
  const [leftScrolPosition, setLeftScrolPosition] = useState(0);
  const [rightScrolPosition, setRightScrolPosition] = useState(0);

  const [checkScrollWidth, setCheckScrollWidth] = useState(0);

  useEffect(() => {
    const divWidth = contentRef.current.scrollWidth;
    setCheckScrollWidth(divWidth);
  }, []);

  const handleScrollLeft = () => {
    if (contentRef.current) {
      const sl = (contentRef.current.scrollLeft -= 100); // Adjust scroll speed as needed
      setLeftScrolPosition(sl);
      setRightScrolPosition(sl);
      //   const  left =this.scrollX;
    }
  };

  const handleScrollRight = () => {
    if (contentRef.current) {
      const sr = (contentRef.current.scrollLeft += 100); // Adjust scroll speed as needed
      setRightScrolPosition(sr);
      setLeftScrolPosition(sr);
    }
  };

  return (
    <div className="max-w-screen-xl mx-auto flex relative">
      {leftScrolPosition > 0 && (
        <span
          className={` ${leftScrolPosition > 0 ? 'cursor-pointer' : ''} w-[40px] h-[40px] z-10 absolute lg:left-[-66px] left-[-30px] top-56 `}
          onClick={handleScrollLeft}
        >
          <svg
            className="greater"
            xmlns="http://www.w3.org/2000/svg"
            width="62"
            height="60"
            viewBox="0 0 24 24"
          >
            <path d="M10.71 16.59L15.3 12l-4.59-4.59L12 6l6 6-6 6z" />
          </svg>
        </span>
      )}

      <div className="flex overflow-x-auto scrollbar-hide gap-8 p-4" ref={contentRef}>

       
         {data.map((item , index) => (
            <div key={index} className="">
            <div className="shadow-md bg-primary    w-[400px] h-fit rounded-2xl">
              <div className="w-full h-fit rounded-2xl">

            <img src={item.img} className="w-full h-[250px]   rounded-2xl" />
              </div>

            <div className="flex flex-col px-4">
              <h2 className="font-bold text-[29px] text-[#1E1666] py-3">
                {item.title}
              </h2>

              <span className="text-[19px] text-[#57647C] font-semibold">{item.smallDesc}</span>
              <Link to={`/casestudy/${item.title}`}>
              <button className="bg-[#0B63D2] hover:bg-primary font-bold text-primary hover:text-[#0B63D2] hover:shadow-lg w-fit rounded-full py-2 p-6 my-4"> READ MORE</button>
              </Link>
            </div>
          </div>
          </div>
        ))}
      
       
      </div>

      {data.length < 4 ||
        (rightScrolPosition < 6000 && (
          <span
            className={` ${rightScrolPosition < 6000 ? 'cursor-pointer' : ''} w-[40px] h-[40px]  z-10 absolute lg:right-[-36px] right-0 top-56`}
            onClick={handleScrollRight}
          >
            <svg
              className="less"
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 24 24"
            >
              <path d="M17.41 7.41L16 6l-6 6 6 6 1.41-1.41L12.83 12z" />
            </svg>
          </span>
        ))}
    </div>
  );
};

export default Slider;

import React from "react";
import Slider from "../../components/clientSlider";
// import Slider from "../../components/pageSlider";
import { client} from "../../type/enum"
const clien = () => {
  return (
    <section id="testimonials" className="max-w-screen-xl lg:mx-auto mx-4 py-16">
      <div className="lg:w-[422px] w-full h-fit   m-auto">

        <h2 className="font-bold md:text-[42px] text-[24px] text-center my-8 ">
          What our Clients are saying about us!
        </h2>

      </div>
      <div>
        <Slider cards={client} is={"client"}/>
 {/* <Slider cards={client} /> */}
        </div>
    </section>
  );
};

export default clien;

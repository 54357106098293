import React, { useEffect, useState } from "react";
import NavbarLayout from "../../../layouts/NavbarLayout";
import Footer from "../../../layouts/Footer";
import image from "../../../assets/aboutUs.gif";
import { portfolios } from "../../../type/enum";
import { useParams } from "react-router-dom";

const ReadMore = () => {
  const { title } = useParams();
  const [portfolio , setPortolio] = useState([])
  useEffect(() => {
    const portfolioDesc = portfolios.filter((item) => item.title.includes(title));
    setPortolio(portfolioDesc)
  }, [title])
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="">
      <NavbarLayout />
      <div className="max-w-screen-xl lg:mx-auto  mx-4 mt-32">
        <div className="grid lg:grid-cols-2 grid-rows my-12 gap-x-20">
          
          <div className="w-full h-fit  rounded-lg  border shadow-lg flex justify-center p-6">
            <img
              src={portfolio[0]?.img}
              className="rounded-lg object-contain"
            />
          </div>

          <div className="flex flex-row">
            <p className="text-[18px]">{portfolio[0]?.desc}</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ReadMore;

// Slider.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const SliderComponent = ({ cards }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Set autoplay interval to 5 secon
    pauseOnHover: true, // Pause autoplay on hover
    mouseWheel: true, // Enable mouse scroll
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

 
  return (
    <Slider {...settings} className='md:mx-8 mx-4'>
      {cards?.map((card, index) => (
        <div key={index}>
          <Card {...card} />
        </div>
      ))}
    </Slider>
  );
};

export default SliderComponent;



export const Card = ({   img }) => {
  return (
    
    <div className=" bg-primary rounded-full border border-[#B7B7B7] mx-2 flex w-[349px] h-[105px]  justify-center items-center">
        <img src={img} className=' w-[250px]  object-contain p-4 ' />
  
  </div>
  );
};



import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

class ContactMap extends React.Component {
  render() {
    const mapStyles = {
      width: '100%',
      height: '550px',
      
    };

    const contactLocation = {
      lat: 33.667223, // Latitude of the contact location
      lng:72.997807 // Longitude of the contact location
    };

    return (
      <div>
        <Map
        
          google={this.props.google}
          zoom={18}
          style={mapStyles}
          initialCenter={contactLocation}
        >
          <Marker position={contactLocation} />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY
})(ContactMap);

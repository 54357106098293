import React from "react";
import { Link } from "react-router-dom";
import upArrow from "../assets/icon/up-arrow.png"
import x from "../assets/icon/x.png"
import fb from "../assets/icon/fb.png"
import youtube from "../assets/icon/youtube.png"
import linkedin from "../assets/icon/in.png"
const Footer = () => {
  return (
    <div className="w-full bg-gradient-to-r from-[#0C59E4] from-0% via-[#138CCD] via-37% to-[#00C9AB] to-96% h-[1300]">
      <div className="max-w-screen-xl lg:mx-auto mx-4 pt-16 flex flex-col   h-fit">
        <div className="flex flex-col md:flex-row w-full justify-between items-center">
          <h2 className="lg:text-[87px] md:text-[60px] md:w-[720px] w-full text-[35px] md:text-left text-center  leading-none tracking-normal">
            <span className="text-primary">Let's Start</span>{" "}
            <span className="text-footerText">Working with Together</span>
          </h2>

          <div className="md:mt-0 mt-10 md:w-[200px] w-full md:text-right text-center">
            <button className="rounded-md bg-primary h-fit p-5 flex">
              <span>Get in Touch</span> <img src={upArrow} className="w-[20px] h-[20px] ml-1 mt-1"/>
            </button>
          </div>
        </div>

        <hr className="w-full   h-[2px] m2-2 my-20 bg-primary border-0 rounded" />

        <div className="flex flex-col lg:flex-row gap-20">
          <div className="flex flex-col">
            <span className="text-[38px] font-medium leading-tight tracking-normal">
              <span className="text-primary ">Get Valueable </span>
              <span className="text-footerText">
                strategy, culture and brand
              </span>
              <span className="text-primary ml-1">
                insights straight to your inbox
              </span>
            </span>

            <span className="text-primary text-[20px] leading-normal tracking-normal py-8">
              By signing up to receive emails from Aeyron, you agree to our
              Privacy Policy. We treat your info responsibly.
            </span>

            <div className="flex justify-between border border-primary bg-[#393939] md:w-[470px] w-full h-[69px] rounded-lg p-1">
              <input
                type="text"
                placeholder="Your email address here"
                className="text-primary placeholder-primary pl-4 text-[20px] bg-[#393939] w-full"
              />
              <button className="rounded-md bg-primary text-secondary h-full text-[20px] px-6">
                Subscribe
              </button>
            </div>
          </div>

          <div>
            <span className="font-medium text-[28px] text-footerText">
              Links
            </span>
            <div className=" text-primary  flex  flex-col gap-4 font-medium text-[23px] mt-8 ">
              
                <a href="/#" className="">
                  Home
                </a>
              
              <a href="/#aboutUs" className="">
                AboutUs
              </a>
              <a href="/#services" className="">
                Services
              </a>
              <a href="/#industry" className="">
                Industry
              </a>
              <a href="/#portfolio" className="">
                Portfolio
              </a>
              <a href="/#testimonials" className="">
                Testimonials
              </a>
              <Link to={"/blogs"}>
                <span href="#" className="">
                  Blogs
                </span>
              </Link>
              <Link to={'/contactUs'}> 
              <span  className="">
                Contact Us
              </span>
              </Link>
              
              <Link to={"/careers"}>
                <span className="">Careers</span>
              </Link>
            </div>
          </div>

          <div>
            <span className="font-medium text-[28px] text-footerText">
              Contact
            </span>

            <div className="flex flex-col mt-8">
              <span className="font-medium text-[18px]   text-primary leading-normal tracking-normal">
                Office no. 5 and 6, 2nd Floor, Capital Plaza, Markaz, G-11
                Markaz G 11 Markaz G-11, Islamabad, Islamabad Capital Territory
                44000
              </span>

              <span className="py-10 text-primary font-medium text-[20px]">
                +92 335 5815205
              </span>

              <span className=" text-primary font-medium text-[20px]">
                khizar@aeyron.com
              </span>
            </div>
          </div>

          <div className="">
            <span className="font-medium text-[28px] text-footerText">
              Contact
            </span>

            <div className="flex lg:flex-col flex-rows gap-3 mt-8">
              <a href="https://www.facebook.com/aeyron2019">
                <img className="w-[30px] h-[30px]" src={fb} />
              </a>
              <a href="https://www.linkedin.com/company/aeyron/?originalSubdomain=pk">
                <img className="w-[30px] h-[30px]" src={linkedin} />
              </a>
              <a href="https://youtube.com/@aeyrontechnologiespvtltd.8635?si=phJvohyiVHRKGRw8">
                <img className="w-[30px] h-[30px]" src={youtube} />
              </a>
              <a href="https://www.twitter.com">
                <img className="w-[30px] h-[30px]" src={x} />
              </a>
              
          
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col md:flex-row">
          <div>1</div>
          <div>2</div>
          <div>3</div>
          <div>4</div>
        </div> */}

        <hr className="w-full   h-[2px] m2-2 mt-20 bg-primary border-0 rounded" />

        <div className="m-auto text-primary my-6">
          <p className="text-center text-[20px]">
            © 2024 Aeyron. All rights reserved | Cookie Settings, Anti-Spam,
            Privacy, User agreement, Legal Notice and Responsible Disclosure
          </p>
        </div>

        {/* 
        <div className="">
          <div className="flex lg:flex-row flex-col justify-between items-center  ">
            <div className="flex  gap-3 md:flex-col flex-col md:mx-1  my-4  text-[#secondary] text-[22px]">
            <a hr="#">Intagram</a>
            <a hr="#">Behance</a>
            <a hr="#">Dribbble</a>
            <a hr="#">Twitter</a>
            <a hr="#">Linkedin</a>
          </div>
        <img src='./logo/logoWhite.png' className="w-[340px] h-[100px] ml-[-30px]" />
      
        </div>

          <hr className="w-full   h-[2px] m2-2 mt-16 bg-secondary border-0 rounded" />

          <div className="m-auto  my-6">
            <p className="text-center text-[20px]">
              © 2024 Aeyron. All rights reserved | Cookie Settings, Anti-Spam,
              Privacy, User agreement, Legal Notice and Responsible Disclosure
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import servicesImg from '../../assets/animation/services.json'
import Lottie from "react-lottie";

const services = () => {
  return (
    <section id="services" className="bg-[#F5FDFF]  py-16">
      <div className="max-w-screen-xl lg:mx-auto  mx-4">

        <div className="flex lg:flex-row md:flex-row flex-col  justify-between">
          <div className=" w-full">
          <h2 className="font-semibold text-[48px]  mb-3 lg:text-left text-center">Our Services</h2>
         
              <div className="mr-4 ">
                <button className="rounded-full cursor-default text-[#0E69D5] py-2 px-4 border border-gray-900 md:text-[31px] text-[16px] m-2">Web Design</button>
                <button className="rounded-full cursor-default text-[#0E69D5] py-2 px-4 border border-gray-900 md:text-[31px] text-[16px] m-2">Chatbots & Generative AI</button>
                <button className="rounded-full cursor-default text-[#0E69D5] py-2 px-4 border border-gray-900 md:text-[31px] text-[16px] m-2">Branding Design</button>
                <button className="rounded-full cursor-default text-[#0E69D5] py-2 px-4 border border-gray-900 md:text-[31px] text-[16px] m-2">AI & Computer Vision</button>
                <button className="rounded-full cursor-default text-[#0E69D5] py-2 px-4 border border-gray-900 md:text-[31px] text-[16px] m-2">UI/UX</button>
                <button className="rounded-full cursor-default text-[#0E69D5] py-2 px-4 border border-gray-900 md:text-[31px] text-[16px] m-2">Web & Mobile Development</button>
         
              </div>
           </div>
          <div className="w-full m-auto object-contain">
          <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: servicesImg,
              }}
             
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default services;

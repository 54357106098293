import React from 'react'
import Slider from '../../components/slider'
import { portfolios ,} from "../../type/enum"
const portfolio = () => {
  return (
    <section id='portfolio' className="bg-[#F5FDFF] py-16">
    <div className="max-w-screen-xl lg:mx-auto  mx-4">

      <div className="">
        <h2 className="font-semibold text-[48px]  text-center">Case Studies</h2>
      </div>
     
            <Slider data={portfolios} is={"porfolio"}/>

        {/* <div className='flex lg:flex-row flex-col justify-between my-16 items-center'>
            <div className='flex flex-col text-secondary lg:text-[70px] text-[35px] font-semibold'>
                <span className='tracking-tighter'>
                Take a Look 
                </span>
                <span className='tracking-tighter flex '>
                at What <span> <hr className="md:w-56 w-36 ml-4 lg:mt-20 mt-9 h-[2px] my-2 bg-secondary border-0 rounded" /></span>
                </span>
            </div>

            <button className='rounded-full w-[214px] h-[214px] bg-btnBg text-center justify-center items-center flex'>
                <span className='w-full text-center font-bold text-primary text-[30px]'>All Work</span>
            </button>

            <div className='flex flex-col text-secondary lg:text-[70px] text-[35px] font-semibold right'>
                <span className='  tracking-tighter '>
                We Can Do
                </span>
                <span className='  tracking-tighter flex '>
                <hr className="md:w-56 w-36 mr-4 lg:mt-20 mt-9 h-[2px] my-2 bg-secondary border-0 rounded" />
               <span> For You</span>
                </span>
            </div>
        </div>
     */}

    </div>
  </section>
  )
}

export default portfolio
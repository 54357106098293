import React, { useEffect } from 'react'
import NavbarLayout from '../../layouts/NavbarLayout'
import Footer from '../../layouts/Footer'
import empty from '../../assets/no-data-found.gif'
import BlogConte from '../../components/blogContent'
const Blogs = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
        <NavbarLayout />
        
        <div className='max-w-screen-xl lg:mx-auto mx-4 py-24'>
        <div className=' flex  mt-8 justify-center'>
              {/* <img src={empty}  className=''/> */}
              <BlogConte />
            </div>
        </div>

        <Footer />

    </>
  )
}

export default Blogs